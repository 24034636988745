export const environment = {
  production: true,
  apiUrl: '',
  captchaSiteKey: '6LfzKOgjAAAAAKIcvjf1DIfNVuMECsxoz678yAXk',
  provinceList: [
    'dolnośląskie',
    'kujawsko-pomorskie',
    'lubelskie',
    'lubuskie',
    'łódzkie',
    'małopolskie',
    'mazowieckie',
    'opolskie',
    'podkarpackie',
    'podlaskie',
    'pomorskie',
    'śląskie',
    'świętokrzyskie',
    'warmińsko-mazurskie',
    'wielkopolskie',
    'zachodniopomorskie',
  ],
};
