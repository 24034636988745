import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { AsyncPipe, NgForOf, NgIf } from '@angular/common';
import { HttpParams } from '@angular/common/http';
import { Component, ElementRef, Input, OnDestroy, OnInit, Optional, Self, ViewChild } from '@angular/core';
import {
  ControlValueAccessor,
  FormControl,
  FormGroup,
  NgControl,
  ReactiveFormsModule,
  UntypedFormBuilder
} from '@angular/forms';
import { MatAutocompleteModule, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { CityModel } from '@app/admin/model/city.model';
import { CityService } from '@shared/service/city.service';
import { UnsubscribeAfterDestroy } from '@shared/unsubscribe-after-destroy';
import { debounceTime, distinctUntilChanged, filter, Observable, of, switchMap } from 'rxjs';
import { finalize, map, tap } from 'rxjs/operators';

@Component({
  selector: 'akk-city-form-field',
  templateUrl: './akk-city-form-group.component.html',
  styleUrls: ['./akk-city-form-group.component.scss'],
  standalone: true,
  imports: [MatFormFieldModule, MatAutocompleteModule, MatInputModule, ReactiveFormsModule, AsyncPipe, NgForOf, NgIf],
})
export class AkkCityFormFieldComponent extends UnsubscribeAfterDestroy implements OnInit, OnDestroy, ControlValueAccessor {
  @Input()
  get value(): CityModel | null {
    return this.selectedCity;
  }
  set value(city: CityModel | null) {
    this.patternControl.setValue({ pattern: city?.name || '' });
  }

  @ViewChild('cityInput') cityInput!: ElementRef<HTMLInputElement>;

  @Input()
  required = true;

  @Input()
  label = 'Miasto';

  @Input()
  invalid = false;

  @Input()
  searchMode = false;

  @Input()
  clearAfterSelect = false;

  @Input()
  hint?: string;

  @Input()
  get disabled(): boolean {
    return this._disabled;
  }
  set disabled(value: boolean) {
    this._disabled = coerceBooleanProperty(value);
    this._disabled ? this.patternControl.disable() : this.patternControl.enable();
  }
  private _disabled = false;

  selectedCity: CityModel | null = null;
  touched = false;
  patternControl: FormGroup<{ pattern: FormControl<CityModel | string> }> = this.fb.group({
    pattern: '',
  });

  filteredCities: Observable<Array<CityModel>> | undefined;

  constructor(@Optional() @Self() public ngControl: NgControl, private fb: UntypedFormBuilder, private cityService: CityService) {
    super();
    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }
  }

  ngOnInit() {
    if (this.searchMode) {
      this.patternControl = this.fb.group({ pattern: this.patternControl.getRawValue().pattern });
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onChange = (city: CityModel | null) => {};
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onTouched = () => {};

  writeValue(city: CityModel): void {
    this.selectedCity = city;
    this.patternControl.setValue({ pattern: city });

    this.filteredCities = this.patternControl.get('pattern')?.valueChanges.pipe(
      tap(() => this.markAsTouched()),
      map(value => value as string),
      debounceTime(300),
      distinctUntilChanged(),
      tap((text: string) => {
        this.onTouched();
        if (!text) {
          this.onChange(null);
        }
      }),
      filter((text: string) => text?.length > 2),
      switchMap((text: string) => this.cityService.findAll(new HttpParams().set('pattern', text))),
      finalize(() => of(''))
    );
  }

  registerOnChange(onChange: any): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any): void {
    this.onTouched = onTouched;
  }

  setDisabledState(isDisabled: boolean) {
    this.disabled = isDisabled;
  }

  displayCityFn(city: CityModel): string {
    return city?.name || '';
  }

  onSelectedCity($event: MatAutocompleteSelectedEvent) {
    this.selectedCity = $event.option.value;
    this.onChange(this.selectedCity);
    if (this.clearAfterSelect) {
      this.cityInput.nativeElement.value = '';
    }
  }

  private markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  onEnter() {
    if (this.searchMode) {
      this.onChange({ name: this.cityInput.nativeElement.value });
    }
  }
}
