import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, HostListener, Output, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { UserProfile } from '@app/core/model/auth/userProfile';
import { AuthenticationService } from '@app/core/services/authentication.service';
import { TrainingTypeEnum } from '@shared/domain/training-type.enum';
import { LinkGenerator } from '@shared/utils';
import { CartService } from '@app/view/cart/cart.service';
import { CartPosition } from '@app/view/cart/model/cart-position';

@Component({
  selector: 'akk-top-bar',
  templateUrl: './top-bar.component.html',
  styleUrls: ['./top-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TopBarComponent {
  LinkGenerator = LinkGenerator;
  TrainingType = TrainingTypeEnum;
  @ViewChild('categoriesMenu') categoriesMenu?: ElementRef;

  @Output()
  toggleSidenav: EventEmitter<void> = new EventEmitter<void>();
  categoryMenuOpen = false;
  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset).pipe(
    map(result => result.matches),
    shareReplay()
  );

  isPc$: Observable<boolean> = this.breakpointObserver.observe([Breakpoints.Medium, Breakpoints.Large, Breakpoints.XLarge]).pipe(
    map(result => result.matches),
    shareReplay()
  );

  userProfile$: Observable<UserProfile | null> = this.authenticationService.userProfile$;
  cart$: Observable<CartPosition[] | null> = this.cartService.cart$;

  cityList$: Subject<Array<string>> = new BehaviorSubject<Array<string>>([
    'Karpacz',
    'Kołobrzeg',
    'Wisła',
    'Zakopane',
    'Międzyzdroje',
    'Augustów',
  ]);
  categoryList$: Subject<Array<string>> = new BehaviorSubject<Array<string>>([
    'Podatkowe',
    'Budżetowe',
    'Rachunkowe',
    'Finansowe',
    'Kadry, Płace, HR',
    'Kursy',
  ]);
  constructor(
    private router: Router,
    private breakpointObserver: BreakpointObserver,
    private authenticationService: AuthenticationService,
    private cartService: CartService
  ) {}

  @HostListener('document:mouseup', ['$event'])
  onGlobalClick(event: Event): void {
    if (!this.categoriesMenu?.nativeElement.contains(event.target)) {
      this.categoryMenuOpen = false;
    }
  }

  onLogout(): void {
    this.authenticationService.logOut();
  }

  onToggleSidenav() {
    this.toggleSidenav.next();
  }

  onToggleCategoryMenu(open: boolean) {
    console.log(open);
    this.categoryMenuOpen = open;
  }

  onCityCatalog(name: string) {
    this.router.navigateByUrl('/szkolenia/katalog?city=' + name);
  }

  onCategoryCatalog(type: TrainingTypeEnum, category: string) {
    switch (type) {
      case TrainingTypeEnum.RECOMMENDED:
        this.router.navigateByUrl('/szkolenia/polecane?category=' + category);
        break;
      case TrainingTypeEnum.ONLINE:
        this.router.navigateByUrl('/szkolenia/online?category=' + category);
        break;
      case TrainingTypeEnum.OUTGOING:
        this.router.navigateByUrl('/szkolenia/wyjazdowe?category=' + category);
        break;
      default:
        this.router.navigateByUrl('/szkolenia/katalog?category=' + category);
    }
  }
}
