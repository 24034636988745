import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { RETURN_URL_PARAM } from '@shared/constants/query-params';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private router: Router) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      map((event: HttpEvent<any>) => {
        return event;
      }),
      catchError((httpErrorResponse: HttpErrorResponse, _: Observable<HttpEvent<any>>) => {
        if (httpErrorResponse.status === 401) {
          this.redirectToLogin();
        }
        throw httpErrorResponse;
      })
    );
  }

  public redirectToLogin() {
    this.router.navigate(['/logowanie'], { queryParams: { [RETURN_URL_PARAM]: this.router.url } }).then();
  }
}
