import { HttpParams } from '@angular/common/http';
import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { SortDirection } from '@angular/material/sort';
import { BehaviorSubject, Observable } from 'rxjs';
import { ProgrammeModel } from '@app/admin/model/programme.model';
import { Programme } from '@shared/domain/programme';
import { SearchFilter } from '@shared/domain/search-filter';
import { Training } from '@shared/domain/training';
import { TrainingBrief } from '@shared/domain/trainingBrief';

function urlEscape(value: string | undefined): string {
  let result: string = value?.replace(/ - /gi, '-').replace(/ /gi, '-').replace(/\//gi, '_') || 'nieznany';
  result = result.replace(/ą/gi, 'a');
  result = result.replace(/ć/gi, 'c');
  result = result.replace(/ę/gi, 'e');
  result = result.replace(/ś/gi, 's');
  result = result.replace(/ł/gi, 'l');
  result = result.replace(/ó/gi, 'o');
  result = result.replace(/ż/gi, 'z');
  result = result.replace(/ź/gi, 'z');
  result = result.replace(/\./gi, '').replace(/,/gi, '');
  return result;
}

export function observeProperty<T, K extends keyof T>(target: T, key: K): Observable<T[K]> {
  const subject = new BehaviorSubject<T[K]>(target[key]);
  Object.defineProperty(target, key, {
    get(): T[K] {
      return subject.getValue();
    },
    set(newValue: T[K]): void {
      if (newValue !== subject.getValue()) {
        subject.next(newValue);
      }
    },
  });
  return subject.asObservable();
}

export class LinkGenerator {
  static donationPath = '/dofinansowanie-szkolen';
  static forSearchTraining(criteria: SearchFilter) {
    let httpParams = new HttpParams();
    if (criteria.text) {
      httpParams = httpParams.set('text', criteria.text);
    }
    if (criteria.city) {
      httpParams = httpParams.set('city', criteria.city);
    }
    return '/szkolenia/szukaj?' + httpParams.toString();
  }
  static forTraining(training: Training | TrainingBrief) {
    return '/szkolenie/' + training.id + '/' + urlEscape(training.title);
  }
  static forProgramme(programme: Programme | ProgrammeModel) {
    return '/szkolenie/program/' + programme.id + '/' + urlEscape(programme.title);
  }

  static forProgrammeWithSelection(programme: Programme | ProgrammeModel, selectedTrainingId: number) {
    return this.forProgramme(programme) + '/' + selectedTrainingId;
  }
}

export class AdminLinkGenerator {
  static trainingView(trainingId: number | undefined) {
    return `/admin/trainings/trainings/${trainingId}`;
  }

  static trainerView(trainerId: number | undefined) {
    return `/admin/trainings/trainings/${trainerId}`;
  }

  static trainingEdit(trainingId: number | undefined) {
    return `/admin/trainings/trainings/${trainingId}/edit`;
  }

  static trainingClone(trainingId: number | undefined) {
    return `/admin/trainings/trainings/${trainingId}/clone`;
  }

  static programmeList() {
    return `/admin/trainings/programme`;
  }

  static programmeView(programmeId: number | undefined) {
    return `/admin/trainings/programme/${programmeId}`;
  }

  static programmeEdit(programmeId: number | undefined) {
    return `/admin/trainings/programme/${programmeId}/edit`;
  }

  static cycleList() {
    return `/admin/trainings/cycle`;
  }

  static cycleView(cycleId: number | undefined) {
    return `/admin/trainings/cycle/${cycleId}`;
  }

  static cycleEdit(cycleId: number | undefined) {
    return `/admin/trainings/cycle/${cycleId}/edit`;
  }

  static programmeClone(programmeId: number | undefined) {
    return `/admin/trainings/programme/${programmeId}/clone`;
  }

  static customerView(customerId: number | undefined) {
    return `/admin/crm/customer/${customerId}`;
  }

  static applicationView(trainingId: number | undefined, applicationId: number | undefined) {
    return `/admin/trainings/trainings/${trainingId}/application/${applicationId}`;
  }

  static orderView(orderNo: string | undefined) {
    return `/admin/sales/orders/${orderNo}`;
  }
}

export function compare(
  a: string | Date | number | undefined,
  b: string | Date | number | undefined,
  direction: SortDirection | undefined
): number {
  if (a === undefined || b === undefined) {
    return 0;
  }
  const isAsc = direction === 'asc';
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}

export const passwordMatchingValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
  const password = control.get('password');
  const confirmPassword = control.get('confirmPassword');

  return password?.value === confirmPassword?.value ? null : { notmatched: true };
};

export const clearInvalidTag = (content: string): string => {
  if (!content) {
    return '';
  }
  return content
    .replace(/(<o:p>)/gi, '<p>')
    .replace(/(<\/o:p>)/gi, '</p>')
    .replace(/(<p><\/p>)/gi, '');
};
