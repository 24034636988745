import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  { path: '', loadChildren: () => import('./view/home/home.module').then(m => m.HomeModule) },
  { path: 'admin', loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule), title: 'Panel admina' },
  { path: 'szkolenia', loadChildren: () => import('./view/trainings/trainings.module').then(m => m.TrainingsModule) },
  { path: 'szkolenie', loadChildren: () => import('./view/training/training.module').then(m => m.TrainingModule) },
  { path: 'konto', loadChildren: () => import('./view/account/account.module').then(m => m.AccountModule), title: 'Konto użytkownika' },
  { path: 'logowanie', loadChildren: () => import('./view/auth/login/login.module').then(m => m.LoginModule), title: 'Logowanie' },
  {
    path: 'rejestracja',
    loadChildren: () => import('./view/auth/register/register.module').then(m => m.RegisterModule),
    title: 'Rejestracja',
  },
  {
    path: 'aktywacja',
    loadChildren: () => import('./view/auth/activate/activate.module').then(m => m.ActivateModule),
    title: 'Aktywacja konta',
  },
  {
    path: 'odzyskiwanie',
    loadChildren: () => import('./view/auth/recovery/recovery.module').then(m => m.RecoveryModule),
    title: 'Odzyskiwanie hasła',
  },
  { path: 'koszyk', loadChildren: () => import('./view/cart/cart.module').then(m => m.CartModule), title: 'Koszyk' },
  { path: 'kontakt', loadChildren: () => import('./view/contact/contact.module').then(m => m.ContactModule), title: 'Kontakt' },
  { path: 'o-nas', loadChildren: () => import('./view/about-us/about-us.module').then(m => m.AboutUsModule), title: 'O nas' },
  {
    path: 'dofinansowanie-szkolen',
    loadChildren: () => import('./view/donation/donation.module').then(m => m.DonationModule),
    title: 'Dofinansowanie szkoleń',
  },
  {
    path: 'regulamin',
    loadComponent: () => import('./view/regulations/regulations.component').then(m => m.RegulationsComponent),
    title: 'Regulamin',
  },
  {
    path: 'polityka-ochrony-danych',
    loadComponent: () => import('./view/policy/policy.component').then(m => m.PolicyComponent),
    title: 'Politycha ochrony danych osobowych',
  },
  {
    path: 'reklamacje',
    loadComponent: () => import('./view/complaints/complaints.component').then(m => m.ComplaintsComponent),
    title: 'Reklamacje',
  },
  { path: 'moje', loadChildren: () => import('./view/mine/mine.module').then(m => m.MineModule), title: 'Moje konto' },
  { path: 'ankieta', loadChildren: () => import('./view/survey/survey.module').then(m => m.SurveyModule), title: 'Ankieta' },
  { path: 'newsletter', loadChildren: () => import('./view/newsletter/newsletter.module').then(m => m.NewsletterModule) },
  { path: 'egzamin', loadChildren: () => import('./view/exam/exam.module').then(m => m.ExamModule) },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
