import { HttpParams } from '@angular/common/http';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, MatSortable } from '@angular/material/sort';

export class SortPageUtil {
  public static createParams(sort: MatSort | null, paginator: MatPaginator): HttpParams {
    if (!paginator) {
      return new HttpParams();
    }
    let params = new HttpParams().set('page', paginator.pageIndex).set('size', paginator.pageSize);
    if (sort?.active && sort.direction) {
      params = params.append('sort', sort.active + ',' + sort.direction);
    }
    return params;
  }

  public static paramsForPage(page: number, size: number) {
    return SortPageUtil.createParams(null, {
      pageIndex: page,
      pageSize: size,
    } as MatPaginator);
  }

  public static params(sort: MatSort | null, page: number, size: number) {
    return SortPageUtil.createParams(sort, {
      pageIndex: page,
      pageSize: size,
    } as MatPaginator);
  }

  public static sortASC(name: string | null): MatSort {
    const matSort = new MatSort();
    if (name) {
      matSort.sort({ id: name, start: 'asc' } as MatSortable);
    }
    return matSort;
  }

  public static sortDESC(name: string | null): MatSort {
    const matSort = new MatSort();
    if (name) {
      matSort.sort({ id: name, start: 'desc' } as MatSortable);
    }
    return matSort;
  }
}
