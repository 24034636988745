import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { RouterLink } from '@angular/router';
import { ConfigService } from '@shared/service/config.service';
import { LinkGenerator } from '@shared/utils';

@Component({
  selector: 'akk-footer',
  standalone: true,
  imports: [CommonModule, RouterLink],
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent {
  LinkGenerator = LinkGenerator;
  constructor(public config: ConfigService) {}
}
