import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ConfigService {
  showOnline$ = new BehaviorSubject<boolean>(true);
  showBudgetary$ = new BehaviorSubject<boolean>(true);
  showOutgoing$ = new BehaviorSubject<boolean>(true);
  showStandard$ = new BehaviorSubject<boolean>(false);
}
