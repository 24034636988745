import { Training } from '@shared/domain/training';
import { TrainingBrief } from '@shared/domain/trainingBrief';

export class TrainingUtils {
  static readonly oneDayInSec = 24 * 60 * 60 * 1000;
  public static isDiscounted(training: Training | TrainingBrief): boolean {
    return (
      (training.discountType &&
        training.discountDueDate &&
        new Date(training.discountDueDate + '') > new Date(new Date().getTime() - TrainingUtils.oneDayInSec)) ||
      false
    );
  }
  public static getCurrentPrice(training: Training | TrainingBrief): number {
    if (this.isDiscounted(training)) {
      return training.discountPrice;
    } else {
      return training.price;
    }
  }
}
