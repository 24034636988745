<akk-top-bar (toggleSidenav)="drawer.toggle()"> </akk-top-bar>
<mat-sidenav-container class="sidenav-container">
  <mat-sidenav
    #drawer
    class="sidenav"
    [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'"
    [mode]="(isHandset$ | async) ? 'over' : 'side'"
    [opened]="(isHandset$ | async) === false && (isSeller$ | async)">
    <ng-container *ngIf="isSeller$ | async">
      <mat-nav-list class="admin-menu">
        <a mat-list-item routerLink="/admin/trainings/trainings" routerLinkActive="active-link">> Szkolenia</a>
        <a mat-list-item routerLink="/admin/sales" routerLinkActive="active-link">> Obsługa sprzedaży</a>
        <a mat-list-item routerLink="/admin/crm" routerLinkActive="active-link">> Obsługa klienta</a>
        <a mat-list-item routerLink="/admin/survey" routerLinkActive="active-link">> Ankiety</a>
      </mat-nav-list>
    </ng-container>
    <ng-container *ngIf="isAdmin$ | async">
      <mat-divider></mat-divider>
      <mat-nav-list class="admin-menu">
        <a mat-list-item routerLink="/admin/cms/content/basic" routerLinkActive="active-link">> Zarządzanie treścią</a>
        <a mat-list-item routerLink="/admin/cms/content/configuration" routerLinkActive="active-link">> Ustawienia</a>
        <a mat-list-item routerLink="/admin/settings" routerLinkActive="active-link">> Konfiguracja</a>
        <a mat-list-item routerLink="/admin/users" routerLinkActive="active-link">> Użytkownicy</a>
        <a mat-list-item routerLink="/admin/newsletter" routerLinkActive="active-link">> Newsletter</a>
        <a mat-list-item href="http://akk.com.pl:4999" routerLinkActive="active-link">> Analityka</a>
        <a mat-list-item routerLink="/admin/certificate" routerLinkActive="active-link">> Certyfikaty</a>
        <a mat-list-item routerLink="/admin/exam" routerLinkActive="active-link">> Egzaminy</a>
      </mat-nav-list>
    </ng-container>
    <mat-nav-list>
      <a mat-list-item routerLink="/">Home</a>
      <a mat-list-item routerLink="/szkolenia" routerLinkActive="active-link">Katalog</a>
      <a mat-list-item [routerLink]="LinkGenerator.donationPath" routerLinkActive="active-link">Dotacje</a>
      <a mat-list-item routerLink="/o-nas" routerLinkActive="active-link">O nas</a>
      <a mat-list-item routerLink="/kontakt" routerLinkActive="active-link">Kontakt</a>
      <mat-divider></mat-divider>
      <a mat-list-item routerLink="/szkolenia/polecane" routerLinkActive="active-link">Szkolenia polecane</a>
      <a mat-list-item routerLink="/szkolenia/online" routerLinkActive="active-link" *ngIf="config.showOnline$ | async">Szkolenia online</a>
      <a mat-list-item routerLink="/szkolenia/wyjazdowe" routerLinkActive="active-link" *ngIf="config.showOutgoing$ | async"
        >Szkolenia wyjazdowe</a
      >
      <a mat-list-item routerLink="/szkolenia/budzetowe" routerLinkActive="active-link" *ngIf="config.showBudgetary$ | async"
        >Szkolenia budżetowe</a
      >
    </mat-nav-list>
  </mat-sidenav>
  <mat-sidenav-content>
    <router-outlet></router-outlet>
    <akk-footer></akk-footer>
  </mat-sidenav-content>
</mat-sidenav-container>
