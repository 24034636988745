import { LayoutModule } from '@angular/cdk/layout';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatBadgeModule } from '@angular/material/badge';
import { MatRippleModule } from '@angular/material/core';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { RouterModule } from '@angular/router';
import { FooterComponent } from '@app/layout/footer/footer.component';
import { TopBarComponent } from '@app/layout/top-bar/top-bar.component';
import { QuickTrainingSearchComponent } from '@shared/components/quick-training-search/quick-training-search.component';
import { IsAdminPipe } from '@shared/pipes/is-admin.pipe';
import { SharedModule } from '@shared/shared.module';
import { MainComponent } from './main/main.component';

@NgModule({
  declarations: [MainComponent, TopBarComponent],
  exports: [MainComponent],
  imports: [
    CommonModule,
    SharedModule,
    LayoutModule,
    MatToolbarModule,
    MatSidenavModule,
    MatListModule,
    RouterModule,
    MatMenuModule,
    IsAdminPipe,
    MatBadgeModule,
    MatRippleModule,
    FooterComponent,
    QuickTrainingSearchComponent,
  ],
})
export class AkkLayoutModule {}
