import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LoginRequest } from '@app/core/model/auth/login-request';
import { UserProfile } from '@app/core/model/auth/userProfile';
import { MemberModel } from '@app/core/model/member.model';
import { RegistrationRequest } from '@app/core/model/registration-request';
import { AppSettings } from '@shared/app-settings';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  readonly ENDPOINT = `${AppSettings.API_ENDPOINT}`;

  constructor(private http: HttpClient) {}

  profile() {
    return this.http.get<UserProfile>(this.ENDPOINT + '/auth/profile');
  }

  login(data: LoginRequest): Observable<LoginRequest> {
    return this.http.post<LoginRequest>(this.ENDPOINT + '/auth/login', data);
  }

  logout() {
    return this.http.get<LoginRequest>(this.ENDPOINT + '/auth/logout');
  }

  register(data: RegistrationRequest, recaptchaToken: string): Observable<MemberModel> {
    return this.http.post<MemberModel>(this.ENDPOINT + '/register', data, { headers: { 'recaptcha-token': recaptchaToken } });
  }
}
