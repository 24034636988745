import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { UserProfile } from '@app/core/model/auth/userProfile';
import { AuthenticationService } from '@app/core/services/authentication.service';
import { ConfigService } from '@shared/service/config.service';
import { LinkGenerator } from '@shared/utils';
import { CartService } from '@app/view/cart/cart.service';
import { CartPosition } from '@app/view/cart/model/cart-position';

@Component({
  selector: 'akk-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss'],
})
export class MainComponent {
  LinkGenerator = LinkGenerator;

  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset).pipe(
    map(result => result.matches),
    shareReplay()
  );
  userProfile$: Observable<UserProfile | null> = this.authenticationService.userProfile$;
  isAdmin$: Observable<boolean> = this.authenticationService.isAdmin$;
  isSeller$: Observable<boolean> = this.authenticationService.isSeller$;
  cart$: Observable<CartPosition[] | null> = this.cartService.cart$;

  constructor(
    public config: ConfigService,
    private breakpointObserver: BreakpointObserver,
    private authenticationService: AuthenticationService,
    private cartService: CartService
  ) {}

  onLogout(): void {
    this.authenticationService.logOut();
  }
}
