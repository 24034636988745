<mat-form-field appearance="outline" class="full-width" role="group" [formGroup]="patternControl">
  <mat-label>{{ label }}</mat-label>

  <input
    matInput
    autofocus
    #cityInput
    [required]="required"
    placeholder="Wybierz miasto"
    [formControl]="patternControl.controls.pattern"
    (keyup.enter)="onEnter()"
    [matAutocomplete]="cityAutocomplete" />
  <mat-autocomplete #cityAutocomplete="matAutocomplete" [displayWith]="displayCityFn" (optionSelected)="onSelectedCity($event)">
    <mat-option *ngFor="let option of filteredCities | async" [value]="option" title="{{ option.name }} (pow. {{ option.county }}) ">
      {{ option.name }} (pow. {{ option.county }})
    </mat-option>
  </mat-autocomplete>
  <mat-hint *ngIf="hint">{{ hint }}</mat-hint>
</mat-form-field>
<mat-error class="city-error" *ngIf="invalid">Wybierz miasto z listy</mat-error>
