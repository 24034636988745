import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppSettings } from '@shared/app-settings';
import { SearchFilter } from '@shared/domain/search-filter';
import { TrainingBrief } from '@shared/domain/trainingBrief';
import { PageModel } from '@shared/page.model';
import { SortPageUtil } from '@shared/sort-page-util';

@Injectable({
  providedIn: 'root',
})
export class TrainingSearchService {
  public static readonly sortBy = {
    dateFrom: 'dateFrom',
    accuracy: 'accuracy',
  };
  readonly ENDPOINT_TRAINING = `${AppSettings.API_ENDPOINT}/training/search`;

  constructor(private http: HttpClient) {}

  search(filter: SearchFilter, params?: HttpParams): Observable<PageModel<TrainingBrief>> {
    if (!params) {
      params = SortPageUtil.paramsForPage(0, 5);
    }
    return this.http.post<PageModel<TrainingBrief>>(this.ENDPOINT_TRAINING, filter, { params });
  }
}
