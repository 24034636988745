import { Injectable } from '@angular/core';
import { UserLocalStorageService } from '@app/core/services/user-local-storage.service';
import { CartOrderData } from '@app/view/cart/model/cart-order-data';
import { CartPosition } from '@app/view/cart/model/cart-position';
import { CreateCustomer } from '@shared/domain/create-customer';
import { CreateOrder } from '@shared/domain/create-order';
import { Person } from '@shared/domain/person';
import { Training } from '@shared/domain/training';
import { TrainingUtils } from '@shared/training-utils';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

export interface CartTableSummary {
  totalPrice: number;
}

@Injectable({
  providedIn: 'root',
})
export class CartService {
  cartOrder: CartOrderData;
  cartPositions: Array<CartPosition>;
  summary: CartTableSummary = {
    totalPrice: 0,
  };

  cartSubject: Subject<Array<CartPosition>> = new BehaviorSubject<Array<CartPosition>>([]);
  cart$: Observable<Array<CartPosition>> = this.cartSubject.asObservable();

  constructor(private storage: UserLocalStorageService) {
    this.cartPositions = this.storage.loadCartPositions() || [];
    if (this.cartPositions) {
      this.cartSubject.next(this.cartPositions);
    }
    this.recalculateSummary();
    this.cartOrder = this.storage.loadCartOrderData() || { members: {}, agr1marketing: false, agr2online: false, agr3tax: false };
  }

  isEmpty() {
    return this.cartPositions.length == 0;
  }

  updateMembers(trainingId: number, members: Array<Person>) {
    this.cartOrder.members[trainingId] = members;
    this.storage.setCartOrderData(this.cartOrder);
  }

  getMembers(trainingId: number): Array<Person> | undefined {
    return this.cartOrder.members[trainingId];
  }

  updateOrderingPerson(person: Person) {
    this.cartOrder.orderingPerson = person;
    this.storage.setCartOrderData(this.cartOrder);
  }

  getOrderingPerson(): Person | undefined {
    return this.cartOrder.orderingPerson;
  }

  updateBuyer(customer: CreateCustomer) {
    this.cartOrder.buyer = customer;
    this.storage.setCartOrderData(this.cartOrder);
  }

  getBuyer(): CreateCustomer | undefined {
    return this.cartOrder.buyer;
  }

  updateAdditionalInfo(info: { additionalCost: number; additionalInformation: Record<string, string> }) {
    this.cartOrder.additionalCost = info.additionalCost;
    this.cartOrder.additionalInformation = info.additionalInformation;
    this.storage.setCartOrderData(this.cartOrder);
  }

  getAdditionalInfo(): { additionalCost: number; additionalInformation: Record<string, string> } | undefined {
    if (this.cartOrder.additionalCost && this.cartOrder.additionalInformation) {
      return {
        additionalCost: this.cartOrder.additionalCost,
        additionalInformation: this.cartOrder.additionalInformation,
      };
    } else {
      return undefined;
    }
  }

  updateRecipient(customer: CreateCustomer | null) {
    this.cartOrder.recipient = customer || undefined;
    this.storage.setCartOrderData(this.cartOrder);
  }

  getRecipient(): CreateCustomer | undefined {
    return this.cartOrder.recipient;
  }

  getInformation(): string | undefined {
    return this.cartOrder.information;
  }

  updateInformation(information: string | null) {
    this.cartOrder.information = information || undefined;
    this.storage.setCartOrderData(this.cartOrder);
  }

  updateAgreements(agr5: boolean, agr6: boolean, agr7: boolean) {
    this.cartOrder.agr1marketing = agr5;
    this.cartOrder.agr2online = agr6;
    this.cartOrder.agr3tax = agr7;
    this.storage.setCartOrderData(this.cartOrder);
  }

  isVatFree(): boolean {
    return this.cartOrder.agr2online;
  }

  getCartPositions(): Array<CartPosition> {
    return this.cartPositions;
  }

  getSummary(): CartTableSummary {
    return this.summary;
  }

  addToCart(product: Training) {
    const existingPosition = this.cartPositions.find((position: CartPosition) => position.product.id === product.id);
    if (existingPosition) {
      existingPosition.amount++;
      this.recalculate(existingPosition);
    } else {
      this.cartPositions.push({ product, amount: 1, price: TrainingUtils.getCurrentPrice(product) });
    }
    this.recalculateSummary();
    this.cartSubject.next(this.cartPositions);
    this.storage.setCartPositions(this.cartPositions);
  }

  deleteFromCart(product: Training) {
    const existingPosition = this.cartPositions.findIndex((position: CartPosition) => position.product.id === product.id);
    if (existingPosition > -1) {
      this.cartPositions.splice(existingPosition, 1);
      this.recalculateSummary();
    }
    this.cartSubject.next(this.cartPositions);
    this.storage.setCartPositions(this.cartPositions);
  }

  changeAmount(product: Training, amount: number) {
    const existingPosition = this.cartPositions.find((position: CartPosition) => position.product.id === product.id);
    if (existingPosition) {
      existingPosition.amount = amount;
      this.recalculate(existingPosition);
    }
    this.cartSubject.next(this.cartPositions);
    this.storage.setCartPositions(this.cartPositions);
  }

  clearCart() {
    this.storage.clearCart();
    this.cartOrder = { members: {}, agr1marketing: false, agr2online: false, agr3tax: false };
    this.cartPositions = [];
    this.cartSubject.next(this.cartPositions);
  }

  prepareOrder(): CreateOrder {
    if (this.cartOrder.orderingPerson) {
      let totalPrice = 0;
      this.cartPositions.forEach(cartPosition => (totalPrice += cartPosition.price));
      return {
        buyer: this.cartOrder.buyer,
        recipient: this.cartOrder.recipient,
        orderingPerson: this.cartOrder.orderingPerson,
        agr1marketing: this.cartOrder.agr1marketing,
        agr2online: this.cartOrder.agr2online,
        agr3tax: this.cartOrder.agr3tax,
        information: this.cartOrder.information,
        positions: this.cartPositions.map(cartPosition => ({
          trainingId: cartPosition.product.id,
          members: this.cartOrder.members[cartPosition.product.id],
        })),
        totalPrice: totalPrice,
        totalAdditionalCost: this.cartOrder.additionalCost,
        additionalInformation: JSON.stringify(this.cartOrder.additionalInformation),
      };
    } else {
      throw new Error('VALIDATION NOT SUPPORTED');
    }
  }

  private recalculate(existingPosition: CartPosition) {
    const price = TrainingUtils.getCurrentPrice(existingPosition.product);
    existingPosition.price = existingPosition.amount * price;
    this.recalculateSummary();
  }

  private recalculateSummary() {
    let totalPrice = 0;
    this.cartPositions.forEach(position => (totalPrice += position.price));
    this.summary.totalPrice = totalPrice;
  }
}
