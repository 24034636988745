<mat-toolbar class="mat-elevation-z2 akk-site-toolbar">
  <button type="button" aria-label="Toggle sidenav" mat-icon-button (click)="onToggleSidenav()" *ngIf="isHandset$ | async">
    <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
  </button>
  <div class="top-logo" [routerLink]="'/'"></div>
  <div class="search-toolbar" *ngIf="isPc$ | async">
    <div class="categories" matRipple *ngIf="categoryMenuOpen" (click)="onToggleCategoryMenu(false)">
      <mat-icon>checklist</mat-icon>
      <div>Kategorie <span class="postfix-categories-name">szkoleń</span></div>
    </div>
    <div class="categories" matRipple *ngIf="!categoryMenuOpen" (click)="onToggleCategoryMenu(true)">
      <mat-icon>checklist</mat-icon>
      <div>Kategorie <span class="postfix-categories-name">szkoleń</span></div>
    </div>
    <akk-quick-training-search></akk-quick-training-search>
  </div>
  <div class="user-toolbar">
    <ng-container *ngIf="!((isHandset$ | async) || false)">
      <div matRipple class="nav-option" [routerLink]="'/szkolenia'">Katalog</div>
      <div matRipple class="nav-option" [routerLink]="LinkGenerator.donationPath">Dotacje</div>
      <div matRipple class="nav-option" [routerLink]="'/o-nas'">O nas</div>
      <div matRipple class="nav-option" [routerLink]="'/kontakt'">Kontakt</div>
    </ng-container>
    <div *ngIf="userProfile$ | async as user; else loginUser">
      <button mat-icon-button [mat-menu-trigger-for]="accountMenu" aria-label="Menu konta użytkownika">
        <mat-icon class="mat-icon-size-32" fontSet="material-icons-outlined">account_circle</mat-icon>
      </button>
      <mat-menu #accountMenu="matMenu">
        <button mat-menu-item disabled>{{ user.email }}</button>
        <mat-divider></mat-divider>
        <button mat-menu-item routerLink="/konto">
          <mat-icon>settings</mat-icon>
          Moje konto
        </button>
        <button mat-menu-item routerLink="/moje/zamowienia">
          <mat-icon>list_alt</mat-icon>
          Moje zamówienia
        </button>
        <button mat-menu-item (click)="onLogout()">
          <mat-icon>logout</mat-icon>
          Wyloguj się
        </button>
      </mat-menu>
    </div>
    <ng-template #loginUser>
      <button mat-icon-button aria-label="Zaloguj się" [routerLink]="'/logowanie'">
        <mat-icon class="mat-icon-size-32" fontSet="material-icons-outlined">account_circle</mat-icon>
      </button>
    </ng-template>
    <button mat-icon-button aria-label="Koszyk" [routerLink]="'/koszyk'">
      <ng-container *ngIf="cart$ | async as positions">
        <mat-icon
          *ngIf="positions.length"
          [matBadge]="positions.length"
          matBadgeColor="warn"
          fontSet="material-icons-outlined"
          class="mat-icon-size-32"
          >shopping_cart</mat-icon
        >
        <mat-icon *ngIf="!positions.length" fontSet="material-icons-outlined" class="mat-icon-size-32">shopping_cart</mat-icon>
      </ng-container>
    </button>
  </div>
</mat-toolbar>
<div #categoriesMenu class="open categories-menu mat-elevation-z1" *ngIf="categoryMenuOpen">
  <div class="categories-container">
    <div class="submenu">
      <a href="/szkolenia/polecane">Szkolenia polecane</a>
      <a matRipple *ngFor="let category of categoryList$ | async" (click)="onCategoryCatalog(TrainingType.RECOMMENDED, category)">{{
        category
      }}</a>
      <a class="last" href="/szkolenia/polecane">Wszystkie</a>
    </div>
    <div class="submenu">
      <a href="/szkolenia/online">Szkolenia Online</a>
      <a matRipple *ngFor="let category of categoryList$ | async" (click)="onCategoryCatalog(TrainingType.ONLINE, category)">{{
        category
      }}</a>
      <a class="last" href="/szkolenia/online">Wszystkie</a>
    </div>
    <div class="submenu">
      <a href="/szkolenia/wyjazdowe">Szkolenia Wyjazdowe</a>
      <a matRipple *ngFor="let category of categoryList$ | async" (click)="onCategoryCatalog(TrainingType.OUTGOING, category)">{{
        category
      }}</a>
      <a class="last" href="/szkolenia/wyjazdowe">Wszystkie</a>
    </div>
    <div class="submenu">
      <div>Miasta</div>
      <a matRipple *ngFor="let city of cityList$ | async" (click)="onCityCatalog(city)">{{ city }}</a>
      <a class="last" href="/szkolenia/katalog">Wszystkie</a>
    </div>
  </div>
</div>
