import { Injectable } from '@angular/core';
import { UserRolesEnum } from '@app/core/model/auth/user-roles.enum';
import { UserProfile } from '@app/core/model/auth/userProfile';
import { AuthService } from '@app/core/services/auth.service';
import { Observable, Subject } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  profileSubject = new Subject<UserProfile | null>();
  userProfile$: Observable<UserProfile | null> = this.profileSubject.asObservable().pipe(shareReplay(1));
  isAdmin$: Observable<boolean> = this.userProfile$.pipe(
    map(userProfile => !!userProfile && userProfile.roles.includes(UserRolesEnum.ROLE_ADMIN)),
    shareReplay(1)
  );
  isSeller$: Observable<boolean> = this.userProfile$.pipe(
    map(
      userProfile =>
        !!userProfile && (userProfile.roles.includes(UserRolesEnum.ROLE_SELLER) || userProfile.roles.includes(UserRolesEnum.ROLE_ADMIN))
    ),
    shareReplay(1)
  );

  constructor(private authService: AuthService) {
    this.refresh();
  }

  public refresh(): void {
    this.authService.profile().subscribe(resp => this.profileSubject.next(resp));
  }

  public logOut(): void {
    this.authService.logout().subscribe(() => {
      this.profileSubject.next(null);
      window.location.reload();
    });
  }
}
