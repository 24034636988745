import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatChipsModule } from '@angular/material/chips';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { ErrorSnackbarComponent } from '@shared/exception/error-snackbar.component';
import { AkkCategoryFormFieldComponent } from '@shared/form/akk-category-form-field/akk-category-form-field.component';
import { AkkCityFormFieldComponent } from '@shared/form/akk-city-form-field/akk-city-form-field.component';
import { ApplicationFormComponent } from '@shared/form/application-form/application-form.component';
import { CustomerFormGroupComponent } from '@shared/form/customer-form-group/customer-form-group.component';
import { PersonFormGroupComponent } from '@shared/form/person-form-group/Person-form-group.component';

@NgModule({
  declarations: [
    ErrorSnackbarComponent,
    ApplicationFormComponent,
    PersonFormGroupComponent,
    CustomerFormGroupComponent,
    AkkCategoryFormFieldComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatInputModule,
    MatIconModule,
    MatAutocompleteModule,
    MatChipsModule,
    AkkCityFormFieldComponent,
  ],
  exports: [
    CommonModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatPaginatorModule,
    MatSnackBarModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    ApplicationFormComponent,
    PersonFormGroupComponent,
    CustomerFormGroupComponent,
    AkkCategoryFormFieldComponent,
  ],
})
export class SharedModule {}
