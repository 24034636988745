import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { TrainingSearchService } from '@shared/service/training-search.service';
import { LinkGenerator } from '@shared/utils';

@Component({
  selector: 'akk-quick-training-search',
  standalone: true,
  imports: [CommonModule, MatFormFieldModule, MatIconModule, MatInputModule, ReactiveFormsModule, MatButtonModule],
  templateUrl: './quick-training-search.component.html',
  styleUrls: ['./quick-training-search.component.scss'],
})
export class QuickTrainingSearchComponent {
  inputText = new FormControl<string>('');
  numberOfAll$: Observable<number> = this.trainingSearchService.search({}).pipe(map(resp => resp.totalElements));
  constructor(private router: Router, private trainingSearchService: TrainingSearchService) {}

  onSearch(): void {
    this.router.navigateByUrl(LinkGenerator.forSearchTraining({ text: this.inputText.value || undefined })).then();
  }
}
