import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class LocalStorageService {
  _localStorage?: Storage;

  constructor(@Inject(PLATFORM_ID) private platformId: object) {
    if (isPlatformBrowser(this.platformId)) {
      this._localStorage = localStorage;
    }
  }

  setInfo<T>(key: string, data: T): void {
    if (this._localStorage) {
      const jsonData = JSON.stringify(data);
      this._localStorage.setItem(key, jsonData);
    }
  }

  loadInfo<T>(key: string): T | null {
    if (this._localStorage && this._localStorage.getItem(key)) {
      return JSON.parse(this._localStorage.getItem(key) as string) as T;
    }
    return null;
  }

  clearInfo(key: string) {
    if (this._localStorage) {
      this._localStorage.removeItem(key);
    }
  }

  clearAllLocalStorage() {
    if (this._localStorage) {
      this._localStorage.clear();
    }
  }
}
