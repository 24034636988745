import { Injectable } from '@angular/core';
import { LocalStorageService } from '@app/core/services/local-storage.service';
import { CartOrderData } from '@app/view/cart/model/cart-order-data';
import { CartPosition } from '@app/view/cart/model/cart-position';

@Injectable({
  providedIn: 'root',
})
export class UserLocalStorageService {
  readonly cartPositionKey = 'cartPositions';
  readonly cartOrderDataKey = 'cartOrderData';

  constructor(private localStorage: LocalStorageService) {}

  setCartPositions(data: Array<CartPosition>): void {
    this.localStorage.setInfo(this.cartPositionKey, data);
  }

  loadCartPositions(): Array<CartPosition> | null {
    return this.localStorage.loadInfo(this.cartPositionKey);
  }

  setCartOrderData(data: CartOrderData): void {
    this.localStorage.setInfo(this.cartOrderDataKey, data);
  }

  loadCartOrderData(): CartOrderData | null {
    return this.localStorage.loadInfo(this.cartOrderDataKey);
  }

  clearCart() {
    this.localStorage.clearInfo(this.cartPositionKey);
    this.localStorage.clearInfo(this.cartOrderDataKey);
  }

  clearAllLocalStorage() {
    this.localStorage.clearAllLocalStorage();
  }
}
