<div class="top-footer">
  <div class="view-container centered restricted spaced">
    <div class="footer-container">
      <div>
        <div class="title">Dane firmy</div>
        <div class="logo"></div>
        <div>
          Akademia Kształcenia Kadr <br />
          Konrad Tagowski <br />
          NIP: 836-186-60-50 <br />
        </div>
        <br />
        <div>
          ul. Składowa 18 <br />
          96-100 Skierniewice <br />
        </div>
      </div>
      <div>
        <div class="title">Kontakt</div>
        <div class="contact">
          <a href="tel:+48601955320">+48 <span>601 955 320</span></a>
          <a href="tel:+48601950841">+48 <span>601 950 841</span></a>
        </div>
        <div class="contact-title">Napisz do nas:</div>
        <div class="contact"><a href="mailto:biuro@akk.com.pl">biuro@akk.com.pl</a></div>
      </div>
      <div>
        <div class="title">Szkolenia</div>
        <div class="menu">
          <a [routerLink]="'/szkolenia'">Katalog szkoleń</a>
          <a [routerLink]="'/szkolenia/online'" *ngIf="config.showOnline$ | async">Szkolenia online</a>
          <a [routerLink]="'/szkolenia/wyjazdowe'" *ngIf="config.showOutgoing$ | async">Szkolenia wyjazdowe</a>
          <a [routerLink]="'/szkolenia/budzetowe'" *ngIf="config.showBudgetary$ | async">Szkolenia budżetowe</a>
          <a [routerLink]="'/szkolenia'" *ngIf="config.showStandard$ | async">Szkolenia stacjonarne</a>
        </div>
      </div>
      <div>
        <div class="title">Menu</div>
        <div class="menu">
          <a [routerLink]="''">Home</a>
          <a [routerLink]="'/o-nas'">O firmie</a>
          <a [routerLink]="LinkGenerator.donationPath">Dofinansowanie szkoleń</a>
          <a [routerLink]="'/kontakt'">Kontakt</a>
          <a [routerLink]="'/koszyk'">Koszyk</a>
          <a [routerLink]="'/regulamin'">Regulamin</a>
          <a [routerLink]="'/polityka-ochrony-danych'">Polityka ochrony danych</a>
          <a [routerLink]="'/reklamacje'">Reklamacje</a>
        </div>
      </div>
    </div>
    <div class="copyright"><div>&#169; 2023. Akademia Kształcenia Kadr</div></div>
  </div>
</div>

<div class="bottom-footer">
  <div class="view-container centered restricted spaced">
    <div>
      Akademia Kształcenia Kadr Konrad Tagowski realizuje projekt dofinansowany z Funduszy Europejskich „Cyfrowa optymalizacja procesów
      sprzedażowych, organizacyjnych oraz zarządczych w aspekcie realizacji szkoleń, poprzez wdrożenie platformy użytkowej dla pracowników
      oraz Klientów przedsiębiorstwa.”
    </div>
  </div>
</div>
