import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CityModel } from '@app/admin/model/city.model';
import { AppSettings } from '@shared/app-settings';
import { PageModel } from '@shared/page.model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

type CityPage = PageModel<CityModel>;

@Injectable({
  providedIn: 'root',
})
export class CityService {
  readonly ENDPOINT = `${AppSettings.API_ENDPOINT}/cities`;

  constructor(private http: HttpClient) {}

  findAll(params: HttpParams): Observable<Array<CityModel>> {
    return this.http.get<CityPage>(this.ENDPOINT, { params }).pipe(map(page => page.content));
  }
}
